import { Button, Col, Form, Input, Row, Switch, Tooltip } from 'antd';
import './EmailReception.less';
import { useContext, useEffect, useState } from 'react';
import { ITenantEmailReceipt } from '../../interfaces/TenantEmailReceipt';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ContextApp } from '../../contexts/ContextApp';
import { PageLoading } from '@ant-design/pro-layout';
import { Mutation } from '../../services/graphql/mutation';
import { ITenant } from '../../interfaces';
import { CustomMessage } from '../../hooks';
import { EnumsValues, TenantStatus } from '../../enums/EnumsValues';
import { IOrder } from '../../interfaces/Order';
import { useForm } from 'antd/lib/form/Form';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import { notificationContext } from '../../contexts/NotificationContext';
type EmailAndDomainInput = {
  email_or_domain: string;
  is_domain: boolean;
};

export const EmailReception = () => {
  const [form1] = useForm();
  const [form2] = useForm();

  const { Query, customRequest } = GraphqlService();
  const [emails, setEmails] = useState<ITenantEmailReceipt[]>();
  const [domains, setDomains] = useState<ITenantEmailReceipt[]>();
  const [tenant, setTenant] = useState<ITenant>();
  const { t, selectedTenantId } = useContext(ContextApp);
  const [loading, setLoading] = useState(true);
  const [emailstoUpdate] = useState<EmailAndDomainInput[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [disabledAll, setDisabledAll] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const updateEmailConfiguration = async () => {
    emailstoUpdate.splice(0, emailstoUpdate.length);
    const form1Values = form1.getFieldsValue();
    const form2Values = form2.getFieldsValue();

    if (
      isActive &&
      form1Values.emails.length === 0 &&
      form2Values.dominios.length === 0
    ) {
      openNotification({
        type: 'error',
        msj: t('EmailReception.message.emailAndDomainRequired'),
        context: 'Receipt.updateEmailConfiguration.1',
      });
      return;
    }

    const errorDomains = form2.getFieldsError();
    const filteredArrayErrorDomains = errorDomains.filter((element: any) => {
      return element.errors.some((error: string) => error.length > 0);
    });
    const errorEmails = form1.getFieldsError();
    const filteredArrayErrorEmails = errorEmails.filter((element: any) => {
      return element.errors.some((error: string) => error.length > 0);
    });

    if (
      filteredArrayErrorDomains.length > 0 ||
      filteredArrayErrorEmails.length > 0
    ) {
      openNotification({
        type: 'error',
        msj: t('EmailReception.message.updateFormErrors'),
        context: 'Receipt.updateEmailConfiguration.1',
      });
      return;
    }

    let inputError: boolean = false;
    if (form1Values.emails.length > 0) {
      for (const email of form1Values.emails) {
        if (email === undefined) {
          openNotification({
            type: 'error',
            msj: t('EmailReception.message.emailRequired'),
            context: 'Receipt.updateEmailConfiguration.1',
          });
          inputError = true;
          break;
        } else {
          emailstoUpdate.push({
            email_or_domain: email,
            is_domain: false,
          });
        }
      }
    }

    if (form2Values.dominios.length > 0) {
      for (const dominio of form2Values.dominios) {
        if (dominio === undefined) {
          openNotification({
            type: 'error',
            msj: t('EmailReception.message.domainsRequired'),
            context: 'Receipt.updateEmailConfiguration.1',
          });
          inputError = true;
          break;
        } else {
          emailstoUpdate.push({
            email_or_domain: dominio,
            is_domain: true,
          });
        }
      }
    }

    if (inputError) {
      return;
    }

    try {
      await customRequest({
        mutation: Mutation.updateEmailConfiguration,
        variables: {
          input: {
            tenant_id: selectedTenantId,
            email_active: isActive,
            emailsAndDomains: emailstoUpdate,
          },
        },
      });
      openNotification({
        type: 'success',
        msj: t('EmailReception.message.success'),
        context: 'Receipt.updateEmailConfiguration.2',
      });
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Receipt.updateEmailConfiguration.3',
      });
    }
  };

  const disabledInputs = async (isActive: boolean) => {
    setIsActive(isActive);

    if (isActive) {
      setDisabledActions(false);
    } else {
      setDisabledActions(true);
    }
  };
  const getEmailsAndDomains = async (tenant_id: number) => {
    const dataEmails: ITenantEmailReceipt[] = await customRequest({
      query: Query.tenantEmailReceipts,
      variables: {
        filter: {
          tenant_id: tenant_id,
          is_domain: false,
        },
      },
    });
    setEmails(dataEmails);

    const dataDomains: ITenantEmailReceipt[] = await customRequest({
      query: Query.tenantEmailReceipts,
      variables: {
        filter: {
          tenant_id: tenant_id,
          is_domain: true,
        },
      },
    });
    setDomains(dataDomains);

    setLoading(false);
  };

  const getTenant = async (tenant_id: number) => {
    const data: ITenant = await customRequest({
      query: Query.tenant,
      variables: {
        input: {
          id: tenant_id,
        },
      },
    });
    if (data) {
      setIsActive(data.integration_by_email);
      setTenant(data);
    }
  };

  const disabledPage = async () => {
    if (tenant?.target?.name === EnumsValues.TargetValues.Telecom) {
      try {
        const order: IOrder = await customRequest({
          query: Query.checkTenantBuy,
          variables: {
            tenant_id: selectedTenantId,
          },
        });

        if (order) {
          setDisabledAll(false);
        }
      } catch (error) {
        //intentional
        setDisabledAll(true);
        setDisabledActions(true);
      }
    }

    if (tenant?.tenant_status_id === TenantStatus.Inactiva) {
      setDisabledAll(true);
      setDisabledActions(true);
    }
  };

  const getInitialConfig = async (selectedTenantId: number) => {
    await getTenant(selectedTenantId);
    await getEmailsAndDomains(selectedTenantId);
  };

  useEffect(() => {
    if (selectedTenantId) {
      getInitialConfig(selectedTenantId);
    }
  }, []);

  useEffect(() => {
    if (tenant) {
      disabledPage();
      disabledInputs(isActive);
    }
  }, [tenant]);

  return loading ? (
    <PageLoading />
  ) : (
    <>
      <div className="container">
        <FeatureFlagWrapper
          featureFlag={EnumsValues.FeatureFlags.emailReception}
        >
          <div
            className={`container-form-email-reception ${
              disabledAll ? 'disabled-color' : ''
            }`}
          >
            <Row>
              <Col span={11}>
                {tenant?.tenant_status_id === TenantStatus.Inactiva ? (
                  <p className="disabled-info">
                    <b>{t('EmailReception.enableFunction')}</b>
                  </p>
                ) : null}
                <div className="EmailReception-header">
                  <div>
                    <p className="EmailReception-header-title">
                      <b className={`${disabledAll ? 'disabled-color' : ''}`}>
                        {t('EmailReception.ReceptionByEmail')}
                      </b>
                    </p>
                  </div>
                  <div>
                    <Switch
                      checked={isActive}
                      onChange={(e) => disabledInputs(e)}
                      disabled={disabledAll}
                    ></Switch>
                  </div>
                </div>
                <p>{t('EmailReception.infoText1')}</p>
                <p>{t('EmailReception.configureInfo')}</p>
                <p>{t('EmailReception.configureStep1')}</p>
                <p>{t('EmailReception.configureStep2')}</p>
                <p>{t('EmailReception.configureStep3')}</p>
                <p>{t('EmailReception.braulioInbox')}</p>
                <Row>
                  <Col span={20}>
                    <Input
                      type="text"
                      className={`input-email ${disabledAll ? 'disabled' : ''}`}
                      readOnly={true}
                      value={tenant?.integration_email}
                      disabled={disabledActions}
                    />
                  </Col>
                  <Col span={4} className="copy-icon">
                    <Tooltip
                      key="copy-email"
                      title={t('EmailReception.tooltip.copyToClipboard')}
                    >
                      <span
                        className={`${
                          disabledAll ? 'disabled' : ''
                        } material-symbols-outlined pointer emailReception-copy-icon`}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            tenant?.integration_email ?? '',
                          );
                        }}
                      >
                        content_copy
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              <hr className="hr-divider"></hr>
              <Col span={11}>
                <p>
                  {t('EmailReception.infoText2')}
                  <b className={`${disabledAll ? 'disabled-color' : ''}`}>
                    {t('EmailReception.enabled')}
                  </b>
                </p>

                <Form
                  form={form1}
                  name="dynamic_form_item"
                  className="dynamic-form-container"
                >
                  <Form.List
                    name="emails"
                    initialValue={emails?.map((item) => item.email_or_domain)}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Form.Item className="label-title">
                          <Row>
                            <Col className="email-label" span={20}>
                              <b
                                className={`${
                                  disabledAll ? 'disabled-color' : ''
                                }`}
                              >
                                {t('EmailReception.accounts')}
                              </b>
                            </Col>
                            <Col
                              span={3}
                              className="email-dynamic-form-container-btn"
                            >
                              <Button
                                disabled={disabledActions}
                                onClick={() => add()}
                                className="btn-new-add "
                              >
                                {
                                  <span className="material-symbols-outlined pointer emailReception-copy-icon">
                                    add_circle
                                  </span>
                                }
                              </Button>
                            </Col>
                          </Row>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                        {fields.map((field, _) => (
                          <Form.Item required={false} key={field.key}>
                            <Row>
                              <Col span={20}>
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  normalize={(value: string) => value?.trim()}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: t(
                                        'EmailReception.message.emailRequired',
                                      ),
                                    },
                                    {
                                      pattern: new RegExp(
                                        '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+[^.]$',
                                      ),
                                      message: t(
                                        'EmailReception.message.wrongFormat',
                                      ),
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    disabled={disabledActions}
                                    placeholder={t(
                                      'EmailReception.placeholder.emailExample',
                                    )}
                                    className="input-email-form "
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                span={3}
                                className="email-dynamic-form-container-btn"
                              >
                                <span
                                  className={`${
                                    disabledActions ? 'disabled' : ''
                                  } material-symbols-outlined pointer emailReception-copy-icon`}
                                  onClick={() =>
                                    !disabledActions && remove(field.name)
                                  }
                                >
                                  delete
                                </span>
                              </Col>
                            </Row>
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form>
                <hr className="hr-mail-divider"></hr>

                <Form
                  form={form2}
                  name="dynamic_form_item_dominio"
                  className="dynamic-form-container"
                >
                  <Form.List
                    name="dominios"
                    initialValue={domains?.map((item) => item.email_or_domain)}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Form.Item className="label-title">
                          <Row>
                            <Col className="email-label" span={20}>
                              <b
                                className={`${
                                  disabledAll ? 'disabled-color' : ''
                                }`}
                              >
                                {t('EmailReception.domains')}
                              </b>
                            </Col>
                            <Col
                              span={3}
                              className="email-dynamic-form-container-btn"
                            >
                              <Button
                                disabled={disabledActions}
                                onClick={() => add()}
                                className="btn-new-add "
                              >
                                {
                                  <span className="material-symbols-outlined pointer emailReception-copy-icon">
                                    add_circle
                                  </span>
                                }
                              </Button>
                            </Col>
                          </Row>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                        {fields.map((field, _) => (
                          <Form.Item required={false} key={field.key}>
                            <Row>
                              <Col span={20}>
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  normalize={(value: string) => value?.trim()}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: t(
                                        'EmailReception.message.domainsRequired',
                                      ),
                                    },
                                    {
                                      pattern: new RegExp(
                                        '^(?!://)(?=.{1,255}$)(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9])(.[A-Za-z]{2,})$',
                                      ),
                                      message: t(
                                        'EmailReception.message.wrongFormat',
                                      ),
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    placeholder={t(
                                      'EmailReception.placeholder.domainExample',
                                    )}
                                    className="input-email-form "
                                    disabled={disabledActions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                span={3}
                                className="email-dynamic-form-container-btn"
                              >
                                <span
                                  className={`${
                                    disabledActions ? 'disabled' : ''
                                  } material-symbols-outlined pointer emailReception-copy-icon`}
                                  onClick={() =>
                                    !disabledActions && remove(field.name)
                                  }
                                >
                                  delete
                                </span>
                              </Col>
                            </Row>
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form>
              </Col>
            </Row>
          </div>
          <Row className="emails-form__buttons">
            <div className="emails-form__buttons--flex">
              <Button
                className={`btn-save ${disabledAll ? 'disabled' : ''}`}
                disabled={disabledAll}
                onClick={updateEmailConfiguration}
              >
                {t('action.save')}
              </Button>
            </div>
          </Row>
        </FeatureFlagWrapper>
      </div>
    </>
  );
};
